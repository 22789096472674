

import _ from 'lodash'
import moment from 'moment'
import cookie from 'js-cookie';
const expires = 365;

export const state = () => ({
    locales: {
        'en': { name: 'English', id: 'en', locale: 'en_US', code: 'en', region: 'US', vuetify: 'en', stripe: 'en' },
        'de': { name: 'DEUTSCH', id: 'de', locale: 'de', code: 'de', region: 'DE', vuetify: 'de' , stripe: 'de'  },
        'fr': { name: 'Français', id: 'fr', locale: 'fr', code: 'fr', region: 'FR', vuetify: 'fr' , stripe: 'fr'  },
        'it': { name: 'Italiano', id: 'it', locale: 'it', code: 'it', region: 'IT', vuetify: 'it' , stripe: 'it'  },
        'es': { name: 'español', id: 'es', locale: 'es', code: 'es', region: 'ES', vuetify: 'es' , stripe: 'es'  },
        'nl': { name: 'Nederland', id: 'nl', locale: 'nl', code: 'nl', region: 'NL', vuetify: 'nl', stripe: 'nl' },
        'cs': { name: 'čeština', id: 'cs', locale: 'cs', code: 'cs', region: 'CZ', vuetify: 'cs', stripe: 'cs' },
    },
    locale: 'en',
    localeInit: false,
    title: "ProWeatherLive",
    args: {},
    actions: [],
    processing: null,
    fullscreen: false,
    fullPage: false,
    noDrawer: false,
    dark: false,
    currency: {
        symbol: '$',
    },
    error: '',
    success: '',

    user: {
        _id: '',
        name: '',
        email: '',
        temperatureUnit: 'F',
        displaySetting: null,
    },
    jwt: '',
    profile: '',
    inited: false,

    settings: {
        deviceId: '',
        publicDevices: [],
    },
    weatherData: {},
    showStation: false,

})


export const getters = {
    margs: state => {
        return _.mapValues(state.args, it => it && it.toString())
    },

    locale: state => {
        const item = state.locales[state.locale];
        return item;
    },

    localeCode: state => {
        const item = state.locales[state.locale];
        return item.locale;
    },

    isVerified() {
        return true;
    },

    userId: state => {
        return state.user && state.user._id;
    },

    displaySetting: state => {
        return _.assign({
            time: 'am/pm',
            date: 'DD/MM',
            direction: 'm',
            bgColor: 'Light',
            temperature: '°C',
            baro: 'Abs',
            baroPressure: 'hpa',
            windSpeed: 'm/s',
            windDirection: '16 Pts',
            rain: 'mm',
            lightIntensity: 'Lux',
            forecast_visibility: 'km',
            probability: '%',
            hcho: 'ppb',
            coco2: 'ppm',
            distance: 'km'
        }, state.user.displaySetting);
    },
}

export const mutations = {
    SET_LANG(state, locale) {
        state.locale = locale
        state.localeInit = true;
    },

    SET_TITLE(state, { title, actions, fullPage, args, noDrawer, dark, showStation }) {
        state.title = title || 'Admin';
        state.fullPage = fullPage || false;
        state.actions = actions || [];
        state.args = args || {};
        state.noDrawer = noDrawer || false;
        state.dark = dark || false;
        state.showStation = showStation || false;
    },

    SET_TITLE_ARGS(state, args) {
        state.args = args || {};
    },

    SET_PROCESSING(state, processing) {
        if (state.processing !== processing)
            state.processing = processing;
    },

    SET_FULLSCREEN(state, fullscreen) {
        state.fullscreen = fullscreen;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },

    SET_SUCCESS(state, success) {
        state.success = success;
    },

    SET_CONNECTED(state, connected) {
        state.connected = connected;
    },

    INIT(state) {
        if(state.inited) return;
        state.inited = true;
        
        const login = localStorage.getItem("login")
        if(login) {
            const info = JSON.parse(login);
            state.user = info.user || {};
            state.jwt = info.jwt;
        }
        const profile = localStorage.getItem("profile")
        state.profile = profile;

        const settings = localStorage.getItem("settings")
        if(settings) {
            try {
                const info = JSON.parse(settings);
                state.settings = info;
            } catch(e) {
                console.warn(e);
            }

        }
        // console.log('inited');
    },

    SET_VERIFYIED(state) {
        state.user.verified = true;
        localStorage.setItem("login", JSON.stringify({
            user: state.user,
            jwt: state.jwt
        }));
    },

    SET_VERIFYTIME(state, value) {
        state.user.verifyTime = value;
        localStorage.setItem("login", JSON.stringify({
            user: state.user,
            jwt: state.jwt
        }));
    },

    SET_USER(state, user) {
        state.user = user || {};
        localStorage.setItem("login", JSON.stringify({
            user: state.user,
            jwt: state.jwt
        }));
    },

    SET_JWT(state, jwt) {
        state.jwt = jwt;
        localStorage.setItem("login", JSON.stringify({
            user: state.user,
            jwt: state.jwt
        }));
    },

    LOGOUT(state) {
        state.user = {};
        state.jwt = "";
        localStorage.setItem("login", JSON.stringify({
            user: state.user,
            jwt: state.jwt
        }));
    },

    SET_USER_PROFILE(state, profile) {
        state.profile = profile;
        // console.log('set user profile pic to ', (profile.substr(0, 100)), profile.length);
        localStorage.setItem("profile", profile || '');
    },


    SET_SETTINGS(state, settings) {
        _.assign(state.settings, settings);
        localStorage.setItem("settings", JSON.stringify(state.settings));
    },

    SET_WEATHER_DATA(state, data) {
        state.weatherData = data;
    },



}

const cookieName = 'locale';

export const actions = {
    async initLang({ state, dispatch, commit }, { ctx }) {
        if(state.localeInit) return;
        if(ctx.$messageQueue.supported) {
            commit('SET_LANG', await ctx.$messageQueue.ns('auth').call('getLocale'));
            ctx.$root.$messageQueue.ns('auth').on('locale', async locale => {
                await ctx.$root.$store.dispatch('changeLang', {id: locale ,ctx})
            });
        }
        let locale = state.locale;
        const lang = (<any>window.navigator).userLanguage || window.navigator.language;
        const l = _.filter(state.locales, l => l.code === lang)[0];
        if (l) locale = l.id;
        dispatch('changeLang', { id: cookie.get(cookieName) || locale, ctx, init: true });
    },

    async changeLang({ commit, state }, { id, ctx, init }) {
        const item = state.locales[id];
        if (item) {
            if (!init) {
                cookie.set(cookieName, id, { expires });
            }
            commit('SET_LANG', id);
            if (ctx.$i18n) ctx.$i18n.locale = id;
            ctx.$vuetify.lang.current = item.vuetify ?? item.locale;
            moment.locale(item.locale);
        }
    },



}
