<template>
  <section class="container">
    <!--img src="~assets/img/logo.png" alt="Nuxt.js Logo" class="logo" width="100" /-->
    <div class="alert alert-error">
      <div class="font-weight-bold">{{ error.statusCode }}</div>
      <p>{{ error.message }}</p>
      <hr />
      <p>
        <nuxt-link class="alert-link" to="/">
          {{ $t("pages.dashboard") }}
        </nuxt-link>
      </p>
    </div>
  </section>
</template>
<script>
export default {
  props: ["error"],
  created() {
    this.$store.commit("SET_TITLE", "Error");
  },
};
</script>

<style scoped></style>
