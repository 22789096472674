var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "action",
          rawName: "v-action.fullscreen",
          value: _vm.fullscreen,
          expression: "fullscreen",
          modifiers: { fullscreen: true }
        },
        {
          name: "event",
          rawName: "v-event.openDialog",
          value: _vm.openDialog,
          expression: "openDialog",
          modifiers: { openDialog: true }
        },
        {
          name: "event",
          rawName: "v-event.modalResult",
          value: _vm.modalResult,
          expression: "modalResult",
          modifiers: { modalResult: true }
        }
      ],
      ref: "app",
      class: [
        "gradientBg",
        { blurView: _vm.showDialog, "full-page-app": _vm.fullPage }
      ],
      style:
        "" +
        (_vm.$vuetify.theme.dark
          ? "background-color: #707070 !important;"
          : "background-color: #175566 !important;"),
      nativeOn: {
        touchstart: function($event) {
          return _vm.startMove($event)
        },
        touchmove: function($event) {
          return _vm.canMove($event)
        },
        touchend: function($event) {
          return _vm.endMove($event)
        }
      }
    },
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "", color: "white", timeout: 100000 },
          model: {
            value: _vm.error,
            callback: function($$v) {
              _vm.error = $$v
            },
            expression: "error"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-4 px-4",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { "align-self": "center", align: "center" } },
                [
                  _c(
                    "div",
                    { staticClass: "greyText--text subtitle-2 text-center" },
                    [_vm._v(_vm._s(_vm.errorMessage))]
                  ),
                  _vm._v(" "),
                  _c("v-btn", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "basic.close",
                        expression: "'basic.close'"
                      }
                    ],
                    staticClass: "mt-2 ma-0",
                    attrs: { text: "", color: "red lighten-2" },
                    nativeOn: {
                      click: function($event) {
                        _vm.error = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "", color: "white" },
          model: {
            value: _vm.success,
            callback: function($$v) {
              _vm.success = $$v
            },
            expression: "success"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-4 px-4",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { "align-self": "center", align: "center" } },
                [
                  _c(
                    "div",
                    { staticClass: "greyText--text subtitle-2 text-center" },
                    [_vm._v(_vm._s(_vm.successMessage))]
                  ),
                  _vm._v(" "),
                  _c("v-btn", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "basic.close",
                        expression: "'basic.close'"
                      }
                    ],
                    staticClass: "mt-2 ma-0",
                    attrs: { text: "", color: "green" },
                    nativeOn: {
                      click: function($event) {
                        _vm.success = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.dialogs, function(dialog) {
        return _c(
          "v-dialog",
          _vm._b(
            {
              key: dialog.key,
              model: {
                value: dialog.show,
                callback: function($$v) {
                  _vm.$set(dialog, "show", $$v)
                },
                expression: "dialog.show"
              }
            },
            "v-dialog",
            dialog.modal,
            false
          ),
          [
            dialog.loading
              ? [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "blue" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : [
                  _c(
                    dialog.component,
                    _vm._b(
                      { tag: "component", attrs: { modalId: dialog.key } },
                      "component",
                      dialog.props,
                      false
                    )
                  )
                ]
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          style:
            "padding: 0px !important; background-color: var(--v-greyInactive-base);}",
          attrs: { dark: "", height: "100%", width: 200, app: "", right: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-layout",
            {
              staticClass: "greyInactive",
              attrs: { column: "", "fill-height": "" }
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      class: {
                        bottomBorder2: index === 6,
                        aboutBorder: index === 0
                      },
                      staticStyle: { "min-height": "40px" },
                      attrs: {
                        to: item.href,
                        target: item.target ? item.target : ""
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "menuText greyText--text pb-1" },
                        [_vm._v(_vm._s(item.title))]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$store.state.fullscreen
        ? _c(
            "v-app-bar",
            {
              staticClass: "top_bar",
              attrs: { height: "72", fixed: "", app: "", flat: "", rounded: "" }
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "fill-height fill-width top_bar_container px-sm-5- py-3 justify-stretch",
                      attrs: { "no-gutters": "", align: "center" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "px-3 fill-height justify-space-between",
                          staticStyle: { position: "relative" },
                          attrs: { "no-gutters": "", align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0",
                                  style:
                                    "margin-left:" +
                                    (_vm.$vuetify.breakpoint.xsOnly
                                      ? "6px"
                                      : "0px") +
                                    ";",
                                  attrs: {
                                    text: "",
                                    depressed: "",
                                    ripple: false,
                                    to: "/dashboard"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("assets/images/PWL_logo.png"),
                                      height: _vm.$vuetify.breakpoint.mdAndUp
                                        ? "40px"
                                        : "25px"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticStyle: { "margin-left": "12px" },
                                    attrs: {
                                      src: require("assets/images/horizontal-logo.png"),
                                      height: _vm.$vuetify.breakpoint.mdAndUp
                                        ? "36px"
                                        : "18px"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto", justify: "end" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex tab_bar",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c(
                                        "div",
                                        { staticClass: "align-self-center" },
                                        _vm._l(_vm.tabs, function(tab) {
                                          return _c(
                                            "v-btn",
                                            {
                                              key: tab.href,
                                              staticClass:
                                                "text-capitalize px-0 mx-3",
                                              attrs: {
                                                text: "",
                                                depressed: "",
                                                ripple: false,
                                                to: tab.href
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(tab.title) +
                                                        "\n                                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tab-underline",
                                                  style:
                                                    "" +
                                                    (_vm.$route.path ===
                                                    tab.href
                                                      ? "background-color:#fff  !important;"
                                                      : "")
                                                })
                                              ])
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                dark: "",
                                                                ripple: false,
                                                                height: _vm
                                                                  .$vuetify
                                                                  .breakpoint
                                                                  .mdAndUp
                                                                  ? 52
                                                                  : 40
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.drawer = false
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .mdAndUp
                                                                        ? 32
                                                                        : 24
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-bars"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3544442820
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                {
                                                  style:
                                                    "background-color: #18687ce0 !important;"
                                                },
                                                _vm._l(_vm.tabs, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: index,
                                                      class: {
                                                        bottomBorder2:
                                                          index === 6,
                                                        aboutBorder: index === 0
                                                      },
                                                      staticStyle: {
                                                        "min-height": "40px"
                                                      },
                                                      attrs: {
                                                        target: item.target
                                                          ? item.target
                                                          : "",
                                                        to: item.href
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "menuText greyText--text pb-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-none py-1 px-xs-0 px-3  rounded-xl d-flex justify-center align-center position-relative",
                                      style:
                                        "background-color: #00BCE588; height: " +
                                        (_vm.$vuetify.breakpoint.mdAndUp
                                          ? 48
                                          : 32)
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "align-center mr-1",
                                                          attrs: {
                                                            fab: "",
                                                            icon: "",
                                                            depressed: "",
                                                            text: "",
                                                            height: "32",
                                                            width: "32"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp
                                                          ? _c("v-img", {
                                                              staticStyle: {
                                                                "border-radius":
                                                                  "50%"
                                                              },
                                                              attrs: {
                                                                src: _vm.userAvatar
                                                                  ? _vm.userAvatar
                                                                  : require("~/assets/images/avatar.svg"),
                                                                height: "32",
                                                                width: "32",
                                                                cover: ""
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3282678961
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-list",
                                            _vm._l(_vm.items, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: index,
                                                  class: {
                                                    bottomBorder2: index === 6,
                                                    aboutBorder: index === 0
                                                  },
                                                  staticStyle: {
                                                    "min-height": "40px"
                                                  },
                                                  attrs: {
                                                    to: item.href,
                                                    target: item.target
                                                      ? item.target
                                                      : ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "menuText greyText--text pb-1"
                                                    },
                                                    [_vm._v(_vm._s(item.title))]
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "align-center",
                                                          attrs: {
                                                            fab: "",
                                                            icon: "",
                                                            depressed: "",
                                                            text: "",
                                                            height: "40",
                                                            width: "40"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-badge",
                                                          {
                                                            attrs: {
                                                              content:
                                                                _vm.activeAlerts
                                                                  .length || "",
                                                              color: _vm.hasAlerts
                                                                ? "alertBadges"
                                                                : "transparent",
                                                              overlap: "",
                                                              dot: ""
                                                            }
                                                          },
                                                          [
                                                            _c("v-img", {
                                                              attrs: {
                                                                src: require("~/assets/images/bell.svg"),
                                                                contain: ""
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            525526875
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "pa-2 rounded-lg",
                                              style:
                                                "background-color: #18687ce0 !important;",
                                              attrs: {
                                                "max-height": "400px",
                                                "min-width": _vm.$vuetify
                                                  .breakpoint.mdAndUp
                                                  ? "373px"
                                                  : "300px"
                                              }
                                            },
                                            _vm._l(_vm.alertDevices, function(
                                              device,
                                              index
                                            ) {
                                              return _c(
                                                "v-list",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "rounded-lg overflow-hidden"
                                                },
                                                _vm._l(
                                                  device.activeAlerts,
                                                  function(item, idx) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: idx,
                                                        staticClass:
                                                          "px-4 bottomBorderDark",
                                                        attrs: {
                                                          justify: "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                staticClass:
                                                                  "greyText--text"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      "no-gutters":
                                                                        "",
                                                                      align:
                                                                        "center"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "auto"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-img",
                                                                          {
                                                                            attrs: {
                                                                              src:
                                                                                device
                                                                                  .deviceTypeInfo
                                                                                  .icond,
                                                                              contain:
                                                                                "",
                                                                              width:
                                                                                "40",
                                                                              height:
                                                                                "40"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex flex-column mr-1 ml-2 mr-md-2 ml-md-3",
                                                                        style:
                                                                          "gap:12px",
                                                                        attrs: {
                                                                          "no-gutters":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        device
                                                                          .device
                                                                          .name ||
                                                                        device
                                                                          .device
                                                                          .wsid
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                " +
                                                                                    _vm._s(
                                                                                      device
                                                                                        .device
                                                                                        .name ||
                                                                                        device
                                                                                          .device
                                                                                          .wsid ||
                                                                                        ""
                                                                                    ) +
                                                                                    "\n                                                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        item.triggerEvent ==
                                                                          "Lost data upload over 30 minutes" ||
                                                                        item.triggerEvent ==
                                                                          "Lost link over 30 minutes"
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                " +
                                                                                    _vm._s(
                                                                                      _vm.formatGroup(
                                                                                        item.itemName
                                                                                      )
                                                                                    ) +
                                                                                    ":\n                                                                " +
                                                                                    _vm._s(
                                                                                      _vm.formatTrigger(
                                                                                        item.itemName,
                                                                                        item.triggerEvent
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                " +
                                                                                    _vm._s(
                                                                                      _vm.formatGroup(
                                                                                        item.itemName
                                                                                      )
                                                                                    ) +
                                                                                    ":\n                                                                " +
                                                                                    _vm._s(
                                                                                      _vm.formatTrigger(
                                                                                        item.itemName,
                                                                                        item.triggerEvent
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                                " +
                                                                                    _vm._s(
                                                                                      item.condition
                                                                                        ? _vm.$t(
                                                                                            "alertCard.is"
                                                                                          ) +
                                                                                            " " +
                                                                                            _vm.$t(
                                                                                              "alertCard." +
                                                                                                item.condition
                                                                                            )
                                                                                        : ""
                                                                                    ) +
                                                                                    "\n                                                                " +
                                                                                    _vm._s(
                                                                                      item.value
                                                                                    ) +
                                                                                    " " +
                                                                                    _vm._s(
                                                                                      item.unit
                                                                                    ) +
                                                                                    "\n                                                            "
                                                                                )
                                                                              ]
                                                                            ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$dateTime(
                                                                                  item.lastTrigger
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "grey0"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "chevron_right"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-main",
        {
          class: [
            "gradientBg",
            { "fill-height": _vm.fullPage },
            { "pt-0": _vm.$route.path === "/world" }
          ],
          staticStyle: {
            background:
              "linear-gradient(180deg, #1A7E97 0%, #1E6D81 49.48%, #21616D 100%) !important"
          }
        },
        [
          _c(
            "v-container",
            {
              staticClass: "gradientBG",
              staticStyle: {
                height: "100%",
                padding: "0px",
                overflow: "hidden",
                background:
                  "linear-gradient(180deg, #1A7E97 0%, #1E6D81 49.48%, #21616D 100%)"
              },
              attrs: { fluid: "" }
            },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("nuxt")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-fab-transition",
        [
          _vm.$store.state.fullscreen
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    fab: "",
                    fixed: "",
                    bottom: "",
                    right: ""
                  },
                  on: { click: _vm.exitFullscreen }
                },
                [_c("v-icon", [_vm._v("fullscreen_exit")])],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }