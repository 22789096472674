import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3b27c664 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _002a7dd3 = () => interopDefault(import('../pages/alert/index.vue' /* webpackChunkName: "pages/alert/index" */))
const _0b01aa88 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _4e6c6ef4 = () => interopDefault(import('../pages/devicefaq.vue' /* webpackChunkName: "pages/devicefaq" */))
const _9df565d8 = () => interopDefault(import('../pages/devices/index.vue' /* webpackChunkName: "pages/devices/index" */))
const _4d3d6bed = () => interopDefault(import('../pages/displaySetting/index.vue' /* webpackChunkName: "pages/displaySetting/index" */))
const _3c66e4f3 = () => interopDefault(import('../pages/export/index.vue' /* webpackChunkName: "pages/export/index" */))
const _ed00f3e6 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _4b43cd40 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _c6c710c0 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _c127cd74 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _4338707c = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7b369ae0 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _bacbcc7c = () => interopDefault(import('../pages/server/index.vue' /* webpackChunkName: "pages/server/index" */))
const _6b25d5a2 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _32b8d557 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _18cf3eaa = () => interopDefault(import('../pages/station/index.vue' /* webpackChunkName: "pages/station/index" */))
const _0877340a = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _43e6bd4e = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _025d76ee = () => interopDefault(import('../pages/world/index.vue' /* webpackChunkName: "pages/world/index" */))
const _0436ce2a = () => interopDefault(import('../pages/account/edit/index.vue' /* webpackChunkName: "pages/account/edit/index" */))
const _8243fb40 = () => interopDefault(import('../pages/login/reset/index.vue' /* webpackChunkName: "pages/login/reset/index" */))
const _2505b3cd = () => interopDefault(import('../pages/profile/changePassword.vue' /* webpackChunkName: "pages/profile/changePassword" */))
const _0bc56f32 = () => interopDefault(import('../pages/profile/changeProfile.vue' /* webpackChunkName: "pages/profile/changeProfile" */))
const _441ce12c = () => interopDefault(import('../pages/settings/alert.vue' /* webpackChunkName: "pages/settings/alert" */))
const _660723a6 = () => interopDefault(import('../pages/settings/devices.vue' /* webpackChunkName: "pages/settings/devices" */))
const _c58732e4 = () => interopDefault(import('../pages/settings/displaySetting.vue' /* webpackChunkName: "pages/settings/displaySetting" */))
const _1579472a = () => interopDefault(import('../pages/settings/languages.vue' /* webpackChunkName: "pages/settings/languages" */))
const _327f04f9 = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _8631fd78 = () => interopDefault(import('../pages/signup/verify.vue' /* webpackChunkName: "pages/signup/verify" */))
const _fa48b8ea = () => interopDefault(import('../pages/subscription/orders.vue' /* webpackChunkName: "pages/subscription/orders" */))
const _0e087b66 = () => interopDefault(import('../pages/subscription/success.vue' /* webpackChunkName: "pages/subscription/success" */))
const _67a0705d = () => interopDefault(import('../pages/login/reset/reset.vue' /* webpackChunkName: "pages/login/reset/reset" */))
const _9531154a = () => interopDefault(import('../pages/login/reset/verify.vue' /* webpackChunkName: "pages/login/reset/verify" */))
const _2cc818fb = () => interopDefault(import('../pages/subscription/_id/payment.vue' /* webpackChunkName: "pages/subscription/_id/payment" */))
const _51c6a7c6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3b27c664,
    name: "account"
  }, {
    path: "/alert",
    component: _002a7dd3,
    name: "alert"
  }, {
    path: "/dashboard",
    component: _0b01aa88,
    name: "dashboard"
  }, {
    path: "/devicefaq",
    component: _4e6c6ef4,
    name: "devicefaq"
  }, {
    path: "/devices",
    component: _9df565d8,
    name: "devices"
  }, {
    path: "/displaySetting",
    component: _4d3d6bed,
    name: "displaySetting"
  }, {
    path: "/export",
    component: _3c66e4f3,
    name: "export"
  }, {
    path: "/faq",
    component: _ed00f3e6,
    name: "faq"
  }, {
    path: "/help",
    component: _4b43cd40,
    name: "help"
  }, {
    path: "/login",
    component: _c6c710c0,
    name: "login"
  }, {
    path: "/logout",
    component: _c127cd74,
    name: "logout"
  }, {
    path: "/privacy",
    component: _4338707c,
    name: "privacy"
  }, {
    path: "/profile",
    component: _7b369ae0,
    name: "profile"
  }, {
    path: "/server",
    component: _bacbcc7c,
    name: "server"
  }, {
    path: "/settings",
    component: _6b25d5a2,
    name: "settings"
  }, {
    path: "/signup",
    component: _32b8d557,
    name: "signup"
  }, {
    path: "/station",
    component: _18cf3eaa,
    name: "station"
  }, {
    path: "/terms",
    component: _0877340a,
    name: "terms"
  }, {
    path: "/test",
    component: _43e6bd4e,
    name: "test"
  }, {
    path: "/world",
    component: _025d76ee,
    name: "world"
  }, {
    path: "/account/edit",
    component: _0436ce2a,
    name: "account-edit"
  }, {
    path: "/login/reset",
    component: _8243fb40,
    name: "login-reset"
  }, {
    path: "/profile/changePassword",
    component: _2505b3cd,
    name: "profile-changePassword"
  }, {
    path: "/profile/changeProfile",
    component: _0bc56f32,
    name: "profile-changeProfile"
  }, {
    path: "/settings/alert",
    component: _441ce12c,
    name: "settings-alert"
  }, {
    path: "/settings/devices",
    component: _660723a6,
    name: "settings-devices"
  }, {
    path: "/settings/displaySetting",
    component: _c58732e4,
    name: "settings-displaySetting"
  }, {
    path: "/settings/languages",
    component: _1579472a,
    name: "settings-languages"
  }, {
    path: "/settings/profile",
    component: _327f04f9,
    name: "settings-profile"
  }, {
    path: "/signup/verify",
    component: _8631fd78,
    name: "signup-verify"
  }, {
    path: "/subscription/orders",
    component: _fa48b8ea,
    name: "subscription-orders"
  }, {
    path: "/subscription/success",
    component: _0e087b66,
    name: "subscription-success"
  }, {
    path: "/login/reset/reset",
    component: _67a0705d,
    name: "login-reset-reset"
  }, {
    path: "/login/reset/verify",
    component: _9531154a,
    name: "login-reset-verify"
  }, {
    path: "/subscription/:id?/payment",
    component: _2cc818fb,
    name: "subscription-id-payment"
  }, {
    path: "/",
    component: _51c6a7c6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
